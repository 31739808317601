import { useState } from 'react'
import { animated, to as interpolate, useSprings } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import { cardsInfo } from '@utils/constants'
import { Card } from './Card'

function to(i: number) {
  return {
    x: 0,
    y: i * -4,
    scale: 1,
    rot: -10 + Math.random() * 20,
    delay: i * 100
  }
}

const from = (_i: number) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })

function trans(r: number, s: any) {
  return `rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`
}

export default function Clients() {
  const [gone] = useState(() => new Set())
  const [props, api] = useSprings(cardsInfo.length, i => ({
    ...to(i),
    from: from(i)
  }))

  const bind = useDrag(
    ({
      args: [index],
      down,
      movement: [mx],
      direction: [xDir],
      velocity
    }) => {
      const trigger = velocity > 0.2
      const dir = xDir < 0 ? -1 : 1
      if (!down && trigger)
        gone.add(index)
      api.start((i): any => {
        if (index !== i)
          return
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0)
        const scale = down ? 1.1 : 1
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 }
        }
      })
      if (!down && gone.size === cardsInfo.length) {
        setTimeout(() => {
          gone.clear()
          api.start(i => to(i))
        }, 600)
      }
    }
  )

  return (
    <div className='container z-3 <sm:scale-82'>
      {props.map(({ x, y, rot, scale }, i) => (
        <animated.div className='deck ' key={i} style={{ x, y }}>
          <animated.div
            {...bind(i)}
            style={{ transform: interpolate([rot, scale], trans) }}
            className='overflow-hidden w-274px h-460px '
          >
            <Card
              author={cardsInfo[i]!.author}
              description={cardsInfo[i]!.description}
              rating={cardsInfo[i]!.rating}
              company={cardsInfo[i]!.company}
              profile={cardsInfo[i]!.profileImg}
            />
          </animated.div>
        </animated.div>
      ))}
    </div>
  )
}
