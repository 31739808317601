export function Card({
  author,
  description,
  rating,
  company,
  profile,
}: {
  author: string
  description: string
  rating: number
  company: string
  profile: string
}) {
  const renderRatingStars = () => {
    const filledStars = Math.floor(rating)
    const hasHalfStar = rating % 1 !== 0

    const stars = Array.from({ length: filledStars }, (_, i) => (
      <span key={i} className="star">
        &#9733;
      </span>
    ))

    const halfStar = hasHalfStar
      ? (
        <span key={filledStars} className="star half-star ">
          &#9733;
        </span>
      )
      : null

    return [...stars, halfStar]
  }

  return (
    <div className=" select-none flex flex-col items-center justify-center text-center w-full h-full bg-white rounded-lg p-20px ">
      <blockquote className="relative z-20 text-shadow-md border-1 border-hex-DADCE1 p-25px w-full h-full rounded-8px">
        <span className=" absolute -top-10px left-4 w-20  text-8xl text-hex-DADCE1 leading-67px h-30px bg-white text-center whitespace-nowrap -tracking-14px <sm:w-2rem <sm:text-5rem">
          ❜ ❜
        </span>
        <div className="flex flex-col items-start justify-start relative -top-1rem <sm:-top-0.8rem">
          <div className="rating-container flex self-end <sm:h-15px">
            {renderRatingStars()}
          </div>
          <p className="text-left text-gray font-400 font-Montserrat text-12px h-135px flex justify-center items-center <lg:text-13px <md:text-12px <sm:!h-120px">{description}</p>
          <div className="flex flex-row items-center justify-center gap-2">
            {profile && <img src={`/images/${profile}.svg`} alt="profile" className="w-60px h-60px <sm:w-35px <sm:h-35px " />}
            <small className="relative flex justify-center items-center z-20 text-hex-94BBFC">
              {!profile && <p>—&nbsp;</p>} <span className="text-14px text-hex-gray font-Montserrat font-700 <sm:text-10px !text-hex-445069">{author}</span>
            </small>
            <span className="text-12px text-black font-Montserrat font-500 <sm:text-8px">{company}</span>
          </div>
        </div>
        <span className=" absolute -bottom-3px right-8 w-20 text-8xl text-hex-DADCE1 leading-67px h-30px bg-white whitespace-nowrap -tracking-14px <sm:w-2rem <sm:text-5rem">
          ❛ ❛
        </span>
      </blockquote>
    </div>
  )
}
